exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artist-js": () => import("./../../../src/pages/artist.js" /* webpackChunkName: "component---src-pages-artist-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-developer-js": () => import("./../../../src/pages/developer.js" /* webpackChunkName: "component---src-pages-developer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-web-maintenance-js": () => import("./../../../src/pages/solutions/web-maintenance.js" /* webpackChunkName: "component---src-pages-solutions-web-maintenance-js" */),
  "component---src-pages-solutions-web-presence-js": () => import("./../../../src/pages/solutions/web-presence.js" /* webpackChunkName: "component---src-pages-solutions-web-presence-js" */),
  "component---src-pages-solutions-website-js": () => import("./../../../src/pages/solutions/website.js" /* webpackChunkName: "component---src-pages-solutions-website-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-thanks-contact-js": () => import("./../../../src/pages/thanks-contact.js" /* webpackChunkName: "component---src-pages-thanks-contact-js" */),
  "component---src-pages-thanks-purchase-js": () => import("./../../../src/pages/thanks-purchase.js" /* webpackChunkName: "component---src-pages-thanks-purchase-js" */),
  "component---src-templates-compare-js": () => import("./../../../src/templates/compare.js" /* webpackChunkName: "component---src-templates-compare-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

